



































import {asArray} from '@labor-digital/helferlein/lib/FormatAndConvert/asArray';
import {ContentElementContext} from '@labor-digital/typo3-vue-framework/lib/Core/Context/ContentElementContext';
import {State} from '@labor-digital/typo3-vue-framework/lib/Core/JsonApi/IdeHelper';
import GreenContainer from '../../../../Component/Layout/GreenContainer/GreenContainer.vue';
import LinkWrap from '../../../../Component/Misc/Link/LinkWrap/LinkWrap.vue';

export default {
    name: 'SeoJobOccupationList',
    components: {LinkWrap, GreenContainer},
    props: {
        context: null as ContentElementContext
    },
    computed: {
        jobOccupations(): Array<State>
        {
            return asArray(this.context.initialState.getAll());
        }
    }
};
